@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;400;500;700&display=swap');

html {
    font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --kpvsBrown: #5B0D00;
    --kpvsBrownLight: rgba(91, 13, 0, 0.5);
    --kpvsGreen: #004D2B;
    --kpvsDropdownButton: #795548;
    --kpvsPaginationGoButtonBorder: #DEE2E6;
    --kpvsGreen500: #4CAF4F;
}

/* Chrome, Safari, Edge, Opera */
/* This removes the up-down buttons in input boxes */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* So font is also Noto Sans TC for inputs */
input, select, textarea, button {
    font-family: inherit;
    font-size: inherit;
}

*:focus {
    outline: none;
}